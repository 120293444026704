import React from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function OrderInvoice({ selectedOrder, closeModal }) {
    return (
        <div className='overlay'>
            <div className='row min-vh-100 pt-5 mt-5'>
                <div className='col-12 col-lg-6 mx-auto align-item-center justify-content-center'>
                    <div className='invoice-card mx-3 bg-white'>
                        <div className='d-flex px-3 align-items-center justify-content-between'>
                            <span className='fs-3'>Invoice</span>
                            <button className='btn fs-3' onClick={closeModal}>
                                <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                            </button>
                        </div>
                        <div className='p-3'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex w-100 justify-content-between align-items-center'>
                                    <span className='fs-3 fw-bolder'>Shaw Tal</span>
                                    <span>NO: {selectedOrder.voucherId}</span>
                                </div>
                                <span className='fs-5 fw-bold'>Laundary Service</span>
                                <div className='w-25 my-2' style={{ 'background-color': 'black','height':'3px' }}></div>
                                <span>Date: {selectedOrder.dateTime}</span>
                            </div>
                            <table className='w-100 my-2'>
                                <thead>
                                    <tr>
                                        <th className='text-start'>Item</th>
                                        {/* <th className='text-end'>Qty</th>
                                    <th className='text-end'>Price</th> */}
                                        <th className='text-end'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedOrder.serviceNameList.map((name, index) => {
                                            return (
                                                <tr key={selectedOrder.serviceIdList[index]}>
                                                    <td className='text-start'>{name} ({selectedOrder.serviceCountList[index]} x {parseInt(selectedOrder.servicePriceList[index]).toLocaleString()} Ks)</td>
                                                    <td className='text-end'>{(parseInt(selectedOrder.serviceCountList[index] * parseInt(selectedOrder.servicePriceList[index]))).toLocaleString()} Ks</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                            <div className='d-flex py-2 justify-content-between' style={{ 'border-top': '1px solid black' }}>
                                <span className='fw-bold'>Total</span>
                                <span className='text-end'>{selectedOrder.totalPrice} Ks</span>
                            </div>
                            <div className='d-flex py-2 justify-content-between' style={{ 'border-top': '1px solid black' }}>
                                <span className='fw-bold'>Status</span>
                                <span className='text-end'>{selectedOrder.paidStatus}</span>
                            </div>
                            <div className='d-flex py-2 justify-content-between' style={{ 'border-top': '1px solid black' }}>
                                <span className='fw-bold'>Comment</span>
                                <span className='text-end'>{selectedOrder.comment}</span>
                            </div>
                            <div className='d-flex flex-column text-center'>
                                <span className='fw-bolder fs-4'>Thank You !</span>
                                <span>Power By Light Idea Software Development Company</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderInvoice