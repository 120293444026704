import React from 'react'

function FillRecordList({records}) {
    return (
        <div>
            <div className='record-box'>
                {records.map(record => {
                    return (
                        <div className='card mb-1 p-0' key={record.id}>
                            <div className='card-body p-1 d-flex flex-column'>
                                <span className='text-black-50'>Date : {record.dateTime}</span>
                                <span className='text-primary'>Service Count: {record.serviceCount}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FillRecordList