import React, { cloneElement, useEffect, useState } from 'react'

import { db } from './firebaseConfig';
import { collection, doc, getDocs,orderBy, onSnapshot, query } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import FillRecordList from './component/FillRecordList';
import UseRecordList from './component/UseRecordList';
import OrderList from './component/OrderList';
import OrderInvoice from './component/OrderInvoice';
function MemberDetail() {
    const [member, setMember] = useState('');
    const [orders, setOrders] = useState([]);
    const [useRecords, setUseRecords] = useState([]);
    const [fillRecords, setFillRecords] = useState([]);
    const [showOrders, setShowOrders] = useState(true);
    const [showUseRecord, setShowUseRecord] = useState(false);
    const [showFillRecord, setShowFillRecord] = useState(false);
    const { id } = useParams();
    const [selectedOrder, setSelectedOrder] = useState('');
    const selectOrder = (order) =>{
        console.log('order from parent',order.voucherId);
        setSelectedOrder(order);
        console.log(selectedOrder);
    }

    useEffect(() => {
        const getUsers = async () => {
            onSnapshot(doc(db, "allMemberCard", id), (doc) => {
                setMember(doc.data())
            })
            // setMember(onSnapshot(memberCardDoc))
        }
        const getOrders = async () => {
            const q = query(collection(db, "allMemberCard", id, "orders"),orderBy("time","desc"))

            onSnapshot(q, (querySnapshot) => {
                setOrders([])
                setOrders(
                    querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    })
                    )
                )
            });
        }
        getUsers();
        getOrders();
    }, []);

    const getUseRecords = async () => {
        const q = query(collection(db, "allMemberCard", id, "useRecord"),orderBy('time','desc'))
        onSnapshot(q, (querySnapshot) => {
            setUseRecords([]);
            setUseRecords(
                querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                })
                )
            )
        });
        setShowFillRecord(false);
        setShowUseRecord(true);
        setShowOrders(false);
        console.log('fill Record', fillRecords)
    }

    const getFillRecords = async () => {
        const q = query(collection(db, "allMemberCard", id, "fillRecord"),orderBy('time','desc'))
        onSnapshot(q, (querySnapshot) => {
            setFillRecords([]);
            setFillRecords(
                querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                })
                )
            )
        });
        setShowFillRecord(true);
        setShowUseRecord(false);
        setShowOrders(false);
    }
    
    const showOrderList = ()=>{
        setShowFillRecord(false);
        setShowUseRecord(false);
        setShowOrders(true);
    }
    const closeModal = () =>{
        setSelectedOrder('');
    }
    return (
        <div className='container'>
            {selectedOrder!=='' && <OrderInvoice selectedOrder={selectedOrder} closeModal={closeModal}/>}

            <div className='row pt-5 mt-3'>

                <div className='col-12 col-md-6'>
                    <h4 className='mb-3'>Member Info ({member.cardType})</h4>

                    <div className='card'>
                        <div className='card-body'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td className='ps-3'>: {member.memberId}</td>
                                    </tr>
                                    <tr>
                                        <th>Name</th>
                                        <td className='ps-3'>: {member.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Phone</th>
                                        <td className='ps-3'>: {member.phone}</td>
                                    </tr>
                                    <tr>
                                        <th>Epire Date</th>
                                        <td className='ps-3'>: {member.expireDate}</td>
                                    </tr>
                                    <tr>
                                        <th>Service Count</th>
                                        <td className='ps-3'>: {member.remainServiceCount}</td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td className='ps-3'>: {member.address}</td>
                                    </tr>
                                </tbody>


                            </table>
                        </div>
                    </div>
                    
                </div>
                <div className='col-12 col-md-6'>
                <h4 className='mt-2'>Use Records</h4>
                    <div className='my-2'>
                        <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                            <label className={showOrders?"btn btn-outline-primary btn-active text-black":'btn btn-outline-primary text-black'} htmlFor="btnradio1" onClick={showOrderList}>Orders</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                            <label className={showUseRecord?"btn btn-outline-primary btn-active text-black":'btn btn-outline-primary text-black'} htmlFor="btnradio2" onClick={getUseRecords}>Usages</label>

                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                            <label className={showFillRecord?"btn btn-outline-primary btn-active text-black":'btn btn-outline-primary text-black'} htmlFor="btnradio3" onClick={getFillRecords}>Extensions</label>
                        </div>
                    </div>

                    {
                        showOrders && 
                       <OrderList orders={orders} selectOrder={selectOrder}/>
                    }
                     {
                        showUseRecord &&
                        <UseRecordList records={useRecords}/>
                    }
                    {
                        showFillRecord &&
                        <FillRecordList records={fillRecords}/>
                    }

                </div>
            </div>
        </div>
    )
}

export default MemberDetail