import React from 'react'

function About() {
  return (
    <div className='container'>
        <div className='row mt-5 pt-5'>
            <div className='col-12'>
                <h1>About us</h1>
            </div>
        </div>
    </div>
  )
}

export default About