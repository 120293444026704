import logo from './Logo.png';
import './App.css';
import Home from './Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import NavBar from './component/NavBar';
import { Route, Routes } from 'react-router-dom';
import MemberDetail from './MemberDetail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import About from './About';
function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/member/:id" element={<MemberDetail />} />
      </Routes>
      <div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>

    </div>
  );
}

export default App;
