import React from 'react';
function OrderList({orders, selectOrder}) {
    return (
        <div className='record-box'>
            {orders.map(order => {
                return (
                    <div className='card mb-1 p-0' key={order.id} onClick={()=>selectOrder(order)}>
                        <div className='card-body p-1 d-flex flex-column'>
                            <span className='text-black-50'>Date : {order.dateTime}</span>
                            <span className='fs-5'>Voucher No: {order.voucherId}</span>
                            {/* <span className='text-primary'>Cost: {order.totalPrice} Ks</span> */}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default OrderList