import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo.png'
function NavBar() {
    return (
        <nav className="navbar fixed-top" style={{'background': '#01bdd6'}}>
            <div className="container">
                <Link to="/" className='navbar-brand'>
                    {/* <img src={Logo} width='40' height='40'/> */}
                    <span className='text-light fw-bold'>Shaw Tal</span>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Shaw Tal</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <Link to='/' className="nav-item text-decoration-none">
                                <a className="nav-link active" aria-current="page">Home</a>
                            </Link>
                            <Link to='/about' className="nav-item text-decoration-none">
                                <a className="nav-link" aria-current="page">About</a>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar