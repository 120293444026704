import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyDji64dLca_hpDmoZxPxj7oocSS8j_DRcI",
  authDomain: "shaw-tal.firebaseapp.com",
  projectId: "shaw-tal",
  storageBucket: "shaw-tal.appspot.com",
  messagingSenderId: "827115347921",
  appId: "1:827115347921:web:51df2c3f0cad471e3f8af0",
  measurementId: "G-W8KZFPTWKQ"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);