import React, { useEffect, useState } from 'react'
import { db } from './firebaseConfig';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import Logo from './Logo.png';
import UserImg from './assets/user.png';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-web';
import Loading from './assets/loading.json';
import { faL, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

function Home() {
    const [members, setMembers] = useState([]);
    const [searchPhone, setSearchPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const searchMember = (e) => {
        setIsLoading(true);
        e.preventDefault();
        console.log(searchPhone);
        const q = query(collection(db, "allMemberCard"), where("phone", "==", searchPhone));
        onSnapshot(q, (querySnapshot) => {
            setMembers(querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id
            })));
        });
        setTimeout(() => {
            setIsLoading(false);
        }, 1000)

    }
    useEffect(() => {
        Lottie.loadAnimation({
            container: document.querySelector("#loading"),
            animationData: Loading
        })
    }, [])
    return (
        <div className='container pt-5'>
            <div className='row'>
                <div className='col-12 col-lg-5 mx-auto my-2'>
                    <div className='mx-auto text-center'>
                        <img src={Logo} width='130' height='130' />
                        <h1 className='title'>Shaw Tal</h1>
                        <h1 className='title-2 mb-1'>Laundary Service</h1>
                        <h5 className='title-3 fw-bold diamond-text'>Check Your Member Card Here !</h5>
                    </div>
                    <div className='card p-0 border-r'>
                        <form onSubmit={searchMember}>
                            <div className='d-flex'>
                                <div className="form-floating" style={{ 'width': '80%' }}>
                                    <input required type="phone" onChange={(e) => { setSearchPhone(e.target.value) }} className="form-control" autoComplete='off' id="floatingInput" placeholder="phone" />
                                    <label htmlFor="floatingInput" className='text-black-50'>Enter Phone Number</label>
                                </div>
                                <button type='submit' className='btn search-btn' style={{ 'width': '20%' }}>
                                    {
                                        isLoading == true ?
                                            <div id='loading' style={{'width':'50px', 'height':'50'}}></div> :
                                            <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>



            </div>
            <div className='row'>
                {isLoading}


                <div className='col-12 col-lg-4 mx-auto'>
                    {

                        members.map((member) => {
                            return (
                                <div className='member-card p-2' key={member.id}>
                                    <Link to={`/member/${member.id}`} className='text-decoration-none diamond-text'>
                                        <div className='my-auto ms-2'>
                                            <div className='d-flex justify-content-between'>
                                                <span>{member.name}</span>
                                                <FontAwesomeIcon icon={faGear}></FontAwesomeIcon>
                                            </div>
                                            <div className='my-4 d-flex justify-content-end'>
                                                <span className='fs-3'>
                                                    Service Count: {member.remainServiceCount}
                                                </span>
                                            </div>
                                            <div className='d-flex justify-content-between py-2'>
                                                <span>{member.memberId}</span>
                                                <span>{member.expireDate}</span>
                                                <div>
                                                    <img src={Logo} width='30' height='30' />
                                                </div>
                                            </div>

                                        </div>
                                    </Link>

                                </div>
                            )
                        })
                    }
                </div>


            </div>

            <div className='footer'>
                <div className='d-flex text-center justify-content-center'>
                    <span style={{ 'fontSize': '10px' }}>Power By <br /> Light Idea Software Development Co.ltd,</span>
                </div>
            </div>
        </div>
    )
}

export default Home